import * as React from "react"
import '../css/bootstrap.min.css';
import '../css/domforte.css';
import {Carousel, CarouselItem, Container, Nav, Navbar, ThemeProvider} from "react-bootstrap";
import {StaticImage} from "gatsby-plugin-image";
import IconDefinitions from "../components/icons/IconDefinitions";
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import DomforteHelmet from "./DomforteHelmet";


const IndexPage = ({data}) => {
    return (
        <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}>
            <DomforteHelmet title={"DOMFORTE - Cracow, ul. Petrażyckiego - Live in the city among the greenery"}/>
            <IconDefinitions/>

            <Navbar bg={'light'} variant={'light'} fixed={'top'} expand={'lg'}>

                <Container>
                    <Navbar.Brand href={'#home'}>
                        <StaticImage src={"../images/logo.webp"} placeholder={"none"} width={200} alt={"logo"}
                                     className={'align-text-bottom'}/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="domforte-navbar"/>
                    <Navbar.Collapse id="domforte-navbar">
                        <Nav className={'ms-auto nav-masthead'}>
                            <Nav.Link href={'#lokalizacja'}>Location</Nav.Link>
                            <Nav.Link href={'#oinwestycji'}>About the investment</Nav.Link>
                            <Nav.Link href={'#domy'}>Houses</Nav.Link>
                            <Nav.Link href={'#dostepnosc'}>Availability</Nav.Link>
                            <Nav.Link href={'#kontakt'}>Contact</Nav.Link>
                            <Nav.Link href={'tel:606388442'}>
                                <svg className="" width="1em" height="1em">
                                    <use href="#phone"/>
                                </svg>
                                &nbsp;606 388 442
                            </Nav.Link>
                            <Nav.Link href={'/'} className={'mx-1 align-bottom'}>
                                <img src={'/staticimg/pl.svg'} alt={"pL"} width={'20px'} height={'10px'}/>
                            </Nav.Link>
                            <Nav.Link href={'/en'} className={'mx-1'}>
                                <img src={'/staticimg/en.svg'} alt={"en"} width={'20px'} height={'10px'}/>
                            </Nav.Link>
                            <Nav.Link href={'/ua'} className={'mx-1'}>
                                <img src={'/staticimg/ua.svg'} alt={"ua"} width={'20px'} height={'10px'}/>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>

                </Container>
            </Navbar>


            <div style={{display: "grid", height: "80vh"}}>
                <StaticImage style={{gridArea: "1/1", zIndex: '-1'}}
                             src={"../images/widok5b_wide.jpg"}
                             placeholder="blurred" alt={"hero"}/>
                <div style={{gridArea: "1/1", position: "relative"}}
                     className="container-fluid d-flex flex-column align-items-center text-center">
                    <div className={'my-auto'}>
                        <h1 className={'display-3 text-white fw-bold bg-info bg-opacity-50'}>Cracow, ul.
                            Petrażyckiego</h1>
                        <h2 className={'display-5 text-white fw-bold bg-opacity-50 df-bg-green'}>
                            Live in the city among the greenery
                        </h2>
                    </div>
                </div>
            </div>


            <div className="container px-4 py-5 retract-top" id="hanging-icons">
                <div className="row">
                    <div className="col-lg-4 my-3">
                        <div className={'mx-4 p-2 bg-light'}>
                            <div className="icon-square-retracted-top">
                                <svg className="bi" width="2em" height="2em">
                                    <use href="#thumbsup"/>
                                </svg>
                            </div>
                            <div className={'p-3'}>
                                <h4>Comfort and convenience</h4>
                                <p className={'text-justify py-4'}> Convenience, functionality and comfort are our
                                    priorities. Each of our houses has three rooms, a living room with kitchen and two
                                    bathrooms on two floors. On the ground floor there is a large open space where
                                    family life takes place. Large sliding patio doors conveniently connect the living
                                    room space with the garden.<br/><br/>
                                </p>
                                <a href="#domy" className="btn btn-primary">
                                    View property plans
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 my-3">
                        <div className={'mx-4 p-2 bg-light'}>
                            <div className="icon-square-retracted-top">
                                <svg className="bi" width="2em" height="2em">
                                    <use href="#quality"/>
                                </svg>
                            </div>
                            <div className={'p-3'}>
                                <h4>Highest quality</h4>
                                <p className={'text-justify py-4'}>The buildings are distinguished not only by their
                                    comfort and convenience, but also by the quality of the materials used and energy
                                    efficiency. We use materials of the highest quality. The windows have warm frames
                                    and highly insulated triple-glazed packages. The high standard of insulation makes
                                    the houses warm and complies with the latest standards.<br/>

                                </p>
                                <a href="#kontakt" className="btn btn-primary">
                                    Learn more
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 my-3">
                        <div className={'mx-4 p-2 bg-light'}>
                            <div className="icon-square-retracted-top">
                                <svg className="bi" width="2em" height="2em">
                                    <use href="#village"/>
                                </svg>
                            </div>
                            <div className={'p-3'}>
                                <h4>Friendly surroundings</h4>
                                <p className={'text-justify py-4'}>The houses are surrounded by green space and new
                                    single-family housing. In the near distance there is a bus stop, a parcel machine, a
                                    grocery store, 4 kindergartens, a school and a station of the Fast Agglomeration
                                    Railway. There is easy access to the centre of Krakow, to the ring road, Zakopianka
                                    and Skawina.<br/><br/><br/>
                                </p>
                                <a href="#lokalizacja" className="btn btn-primary">
                                    Read about the location
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className={'container px-4 py-4 my-4'}>
                <div className={'pb-3'}><h1 className={'display-4 fw-bold sectionTitle'}>Sales to foreigners</h1></div>
                <div className={"px-4 py-4 text-justify"}>
                    The purchase of the premises does not require a permit from the Ministry of the Interior. This
                    applies to all foreigners. Residents of the European Union and the European Economic Area do not
                    need to obtain a permit. Citizens of other countries, such as Ukraine and Belarus, do not need to
                    obtain a permit. The units are sold as independent residential units.
                </div>
            </div>
            <a name={'lokalizacja'}/>
            <div className={'container px-4 py-4 my-5'}>
                <div className={'row'}>
                    <div className={'col-md-6'}>
                        <div className={'pb-3'}><h1 className={'display-4 fw-bold sectionTitle'}>Enjoy the fresh
                            air</h1></div>
                        <div className={"px-4 py-4 text-justify"}>The investment is located in Cracow at ul.
                            Petrażyckiego 83 in the area of Cracow known as Sidzina - it is the area of Cracow forming
                            part of District VIII Debniki, a former village near Cracow annexed to the city of Cracow in
                            1973. Sidzina is the southernmost part of District VIII, located outside the motorway ring
                            road. The nearest neighbourhood of the estate consists of single-family housing and
                            undeveloped areas. You will find here unique walking areas, a school, kindergartens, shops,
                            as well as a great access to the centre of Cracow thanks to the Fast Agglomeration Railway,
                            MPK and good road connections with Ruczaj, Cracow ring road, Zakopianka and Skawina.

                            <br/><br/>
                            <a href={'https://goo.gl/maps/syCSeh2v9r6iHrwx7'}>See on Google maps (Cracow, ul.
                                Petrażyckiego 83)
                            </a>
                        </div>
                    </div>
                    <div className={'col-md-6'}>
                        <div className={'card shadow-lg'}>
                            <StaticImage src={"../images/mapa1.png"} placeholder="" alt={"mapa1"}
                                         className={'align-text-bottom'}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'container px-4 py-4'}>
                <div className={'row'}>
                    <div className={'col-md-2 bg-light mx-3 my-5'}>
                        <div className="icon-square-retracted-top">
                            <svg className="bi" width="2em" height="2em">
                                <use href="#train"/>
                            </svg>
                        </div>
                        <div className={'py-4 px'}>
                            Railway station and Fast Railway Station <b>800m</b>.
                        </div>
                    </div>
                    <div className={'col-md-2 bg-light mx-3 my-5'}>
                        <div className="icon-square-retracted-top">
                            <svg className="bi" width="2em" height="2em">
                                <use href="#bus"/>
                            </svg>
                        </div>
                        <div className={'py-4'}>
                            MPK bus stop (175,243,475) <b>150m</b>
                        </div>
                    </div>
                    <div className={'col-md-2 bg-light mx-3 my-5'}>
                        <div className="icon-square-retracted-top">
                            <svg className="bi" width="2em" height="2em">
                                <use href="#school"/>
                            </svg>
                        </div>
                        <div className={'py-4'}>
                            Primary School no. 133 in Krakow <b>1100m</b>
                        </div>
                    </div>
                    <div className={'col-md-2 bg-light mx-3 my-5'}>
                        <div className="icon-square-retracted-top">
                            <svg className="bi" width="2em" height="2em">
                                <use href="#kindergarten"/>
                            </svg>
                        </div>
                        <div className={'py-4'}>
                            Four kindergartens <b>850m - 1100m</b>
                        </div>
                    </div>
                    <div className={'col-md-2 bg-light mx-3 my-5'}>
                        <div className="icon-square-retracted-top">
                            <svg className="bi" width="2em" height="2em">
                                <use href="#supermarket"/>
                            </svg>
                        </div>
                        <div className={'py-4'}>
                            Grocery shop (Lewiatan supermarket) and bakery <b>500m</b>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'container px-4 py-4 my-5'}>
                <div className={'row'}>
                    <div className={'col-md-6'}>
                        <div className={'pb-3'}><h1 className={'display-5 fw-bold sectionTitle'}>Everything on the
                            spot</h1></div>
                        <div className={"px-4 py-4 text-justify"}>
                            In the neighbourhood you will find a primary school - 800m, a grocery supermarket - 500m, a
                            bakery - 450m, four kindergartens at a distance of 850m to 1100m, the sports club “Sidzina”,
                            a community centre and a church.
                        </div>
                        <div className={'pb-3'}><h1 className={'display-5 fw-bold sectionTitle'}>Fast access to the
                            centre</h1>
                        </div>
                        <div className={"px-4 py-4 text-justify"}>
                            There is an MPK bus stop 150m away for bus numbers 175, 243 and 475. Thanks to the train
                            station and the Fast Agglomeration Railway, you can reach the Central Railway Station and
                            the Cracow Market Square area in 20 minutes. Very good road connections - exit to the ring
                            road - Skawina junction - 4 minutes, Zakopiańska junction - 6 minutes. You can get to Ruczaj
                            in 7 minutes thanks to Sidziński crossing over the ring road - Działowskiego street.
                        </div>

                    </div>
                    <div className={'col-md-6'}>
                        <div className={'card shadow-lg'}>
                            <StaticImage src={"../images/mapa2.png"} placeholder="" alt={"mapa2"}
                                         className={'align-text-bottom'}/>
                        </div>
                    </div>
                </div>
            </div>


            <a name={'oinwestycji'}/>
            <div className={'container-fluid px-0 py-4 my-5'} style={{display: "grid"}}>
                <StaticImage style={{gridArea: "1/1"}} layout="fullWidth" src={"../images/salon_wide.jpeg"}
                             placeholder="blurred" alt={""}/>
                <div style={{gridArea: "1/1", position: "relative"}}
                     className="container-fluid d-flex flex-column align-items-center">
                    <div className={'row my-auto w-75 h-100'}>
                        <div className={'col-lg-6 bg-white my-auto p-5'}>
                            <div><h1 className={'display-5 fw-bold sectionTitle text-left ;h'}>Live in comfort</h1>
                            </div>
                            <div className={'pt-4'}>
                                <p className={'text-justify'}>DOMFORTE Petrażyckiego is an intimate housing estate
                                    consisting of three two-family houses. Each two-level segment ranges from
                                    approximately 78m2 to 85m2. The houses have spacious living rooms connected with a
                                    kitchenette, on the first floor there are three rooms. Each house has two bathrooms.
                                </p>
                                <br/>
                                <p className={'text-justify'}>
                                    The segments have their own independent gardens, which are great places to spend
                                    time with the family outdoors, to play with the children or to organise a barbecue
                                    for friends and family. Each house has its own independent parking space.
                                </p><br/>
                                <p className={'text-justify'}>
                                    Modern architecture, at the same time preserving the elegant form of the buildings
                                    and very high functionality of rooms are the advantages of our investment.
                                </p>
                                <br/>
                            </div>
                        </div>
                        <div className={'col-lg-6'}>
                        </div>

                    </div>
                </div>
            </div>

            <div className={'container px-4 py-4 my-5'}>
                <div className={'row'}>
                    <div className={'col-md-7 text-end'}>
                        <div className={"mx-auto"}>
                            <LightGallery plugins={[lgZoom]} mode="lg-fade">
                                <a href={'/staticimg/salon.jpg'}>
                                    <img src={'/staticimg/salon_small.jpeg'} className={'mx-1 my-1'}/>
                                </a>
                                <a href={'/staticimg/kuchnia.jpg'}>
                                    <img src={'/staticimg/kuchnia_small.jpeg'} className={'mx-1 my-1'}/>
                                </a>
                                <a href={'/staticimg/lazienka.jpg'}>
                                    <img src={'/staticimg/lazienka_small.jpeg'} className={'mx-1 my-1'}/>
                                </a>
                                <a href={'/staticimg/widok2b.jpg'}>
                                    <img src={'/staticimg/widok2b_small.jpg'} className={'mx-1 my-1'}/>
                                </a>
                                <a href={'/staticimg/widok3b.jpg'}>
                                    <img src={'/staticimg/widok3b_small.jpg'} className={'mx-1 my-1'}/>
                                </a>
                                <a href={'/staticimg/widok4b.jpg'}>
                                    <img src={'/staticimg/widok4b_small.jpg'} className={'mx-1 my-1'}/>
                                </a>
                                <a href={'/staticimg/widok5b.jpg'}>
                                    <img src={'/staticimg/widok5b_small.jpg'} className={'mx-1 my-1'}/>
                                </a>
                                <a href={'/staticimg/widok6b.jpg'}>
                                    <img src={'/staticimg/widok6b_small.jpg'} className={'mx-1 my-1'}/>
                                </a>
                                <a href={'/staticimg/widok7b.jpg'}>
                                    <img src={'/staticimg/widok7b_small.jpg'} className={'mx-1 my-1'}/>
                                </a>
                                <a href={'/staticimg/widok1a_wide.jpeg'}>
                                    <img src={'/staticimg/widok1a_small.jpeg'} className={'mx-1 my-1'}/>
                                </a>
                            </LightGallery>
                        </div>
                    </div>
                    <div className={'col-md-4 my-5 my-md-0'}>
                        <p className={'text-justify'}>
                            The buildings are characterised by simple but elegant and modern architecture. The large
                            window area provides light and a pleasant atmosphere in each room. A huge sliding window in
                            the living room allows the living room and garden to seamlessly merge into a pleasant, green
                            and safe living space for you and your family. The use of the highest quality materials
                            combined with the high quality of building and installation of its elements ensures many
                            years of trouble-free use.
                        </p>
                        <br/>
                        <p className={'text-justify'}>
                            The houses are very well insulated, heated by a dual-function gas furnace with very modern
                            characteristics, which reduces heating costs. The upstairs rooms are full height - there are
                            no slants. The DOMFORTE Petrażycki estate will be an excellent choice for couples and
                            families with children, giving them high comfort and convenience in a green environment.
                        </p>
                    </div>
                </div>
            </div>
            <div className={'container px-4 py-4'}>
                <div className={'row'}>
                    <div className={'col-md-3 px-5'}>
                        <div className="icon-square">
                            <svg className="bi" width="2em" height="2em">
                                <use href="#slonce"/>
                            </svg>
                        </div>
                        <h4 className={'pt-3'}>Lots of sunshine in your home</h4>
                        <div className={'py-4'}>
                            <p className={'text-justify padding-5'}>The large window area means that all rooms are very
                                well lit with natural sunlight, ensuring a pleasant atmosphere and comfortable
                                conditions for work, study and play for the whole family.</p>
                        </div>
                    </div>
                    <div className={'col-md-3  px-5'}>
                        <div className="icon-square">
                            <svg className="bi" width="3em" height="3em">
                                <use href="#przesuwneokno"/>
                            </svg>
                        </div>
                        <h4 className={'pt-3'}>Large sliding window in the living room</h4>
                        <div className={'py-4'}>
                            <p className={'text-justify padding-5'}>The living room has a huge 320cm wide window that
                                opens by sliding to create a 160cm wide passageway opening, it has no threshold and
                                allows a very comfortable connection between the living space of the living room and the
                                garden</p>

                        </div>
                    </div>
                    <div className={'col-md-3  px-5'}>
                        <div className="icon-square">
                            <svg className="bi" width="2em" height="2em">
                                <use href="#pelnawysokosc"/>
                            </svg>
                        </div>
                        <h4 className={'pt-3'}>Full height without slant</h4>
                        <div className={'py-4'}>
                            <p className={'text-justify padding-5'}>Our houses have a full height floor, which makes the
                                entire room space suitable for efficient use. Slants often make it difficult to use the
                                space comfortably and also, due to the necessity of finishing with plasterboard, they
                                often become a place of cracks and damage.</p>

                        </div>
                    </div>
                    <div className={'col-md-3  px-5'}>
                        <div className="icon-square">
                            <svg className="bi" width="2em" height="2em">
                                <use href="#ogrzewanie"/>
                            </svg>
                        </div>
                        <h4 className={'pt-3'}>Cost-effective heating</h4>
                        <div className={'py-4'}>
                            <p className={'text-justify padding-5'}>Houses are very well insulated with polystyrene foam
                                with low thermal conductivity coefficient (0.031) and heated with very good quality
                                modern bifunctional gas cookers, so you get precise control over operating and heating
                                costs.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'container px-4 py-4 my-5 bg-white'}>
                <Carousel interval={1500}>
                    <CarouselItem>
                        <StaticImage className="d-block w-100" src="../images/kuchnia_wide.jpeg" alt={"1"}/>
                    </CarouselItem>
                    <CarouselItem>
                        <StaticImage className="d-block w-100" src="../images/lazienka_wide.jpeg" alt={"2"}/>
                    </CarouselItem>
                    <CarouselItem>
                        <StaticImage className="d-block w-100" src="../images/salon_wide.jpeg" alt={"3"}/>
                    </CarouselItem>
                    <CarouselItem>
                        <StaticImage className="d-block w-100" src="../images/widok2b.jpg" alt={"4"}/>
                    </CarouselItem>
                    <CarouselItem>
                        <StaticImage className="d-block w-100" src="../images/widok3b.jpg" alt={"5"}/>
                    </CarouselItem>
                    <CarouselItem>
                        <StaticImage className="d-block w-100" src="../images/widok4b.jpg" alt={"6"}/>
                    </CarouselItem>
                    <CarouselItem>
                        <StaticImage className="d-block w-100" src="../images/widok5b.jpg" alt={"7"}/>
                    </CarouselItem>
                    <CarouselItem>
                        <StaticImage className="d-block w-100" src="../images/widok6b.jpg" alt={"8"}/>
                    </CarouselItem>
                    <CarouselItem>
                        <StaticImage className="d-block w-100" src="../images/widok7b.jpg" alt={"9"}/>
                    </CarouselItem>
                </Carousel>
            </div>

            <a name={'domy'}/>
            <div className={'container px-4 py-4 my-5'}>
                <div><h1 className={'display-4 fw-bold sectionTitle'}>Learn more about your dream home</h1></div>

                <div className={'pt-5'}>
                    <h3>House variant 1 - 84,65m2</h3>(84,65m2 usable area + 1,90 m2 usable ancillary area (h&lt;1.9m))

                </div>
                <div className={'row my-5'}>
                    <div className={'col-md-8'}>
                        <StaticImage src={"../images/plan_parter.jpg"} placeholder="" alt={"plan_parter"}
                                     className={'align-text-bottom'}/>

                    </div>
                    <div className={'col-md-4'}>
                        <h4>Ground floor</h4>41,96m2 usable area +<br/> 1,90 m2 usable ancillary area (h&lt;1.9m)
                        <div style={{"overflowX": "scroll"}}>
                            <table className={'w-100 niceTable mt-5'}>
                                <thead className={'text-capitalize'}>
                                <tr>
                                    <th>NR</th>
                                    <th>ROOM</th>
                                    <th>AREA[m2]</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className={'text-start'}>1</td>
                                    <td className={'text-start'}>VESTIBULE</td>
                                    <td className={'text-start'}>4,13</td>
                                </tr>
                                <tr>
                                    <td className={'text-start'}>2</td>
                                    <td className={'text-start'}>BATHROOM</td>
                                    <td className={'text-start'}>3,21</td>
                                </tr>
                                <tr>
                                    <td className={'text-start'}>3</td>
                                    <td className={'text-start'}>LIVING ROOM WITH KITCHENETTE</td>
                                    <td className={'text-start'}>34,62</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className={'row my-5'}>
                    <div className={'col-md-8'}>
                        <StaticImage src={"../images/plan_pietro.jpg"} placeholder="" alt={"plan_pietro"}
                                     className={'align-text-bottom'}/>

                    </div>
                    <div className={'col-md-4'}>
                        <h4>First Floor</h4>42,69m2 usable area
                        <div style={{"overflowX": "scroll"}}>
                            <table className={'w-100 niceTable mt-5'}>
                                <thead className={'text-capitalize'}>
                                <tr>
                                    <th>NR</th>
                                    <th>ROOM</th>
                                    <th>AREA[m2]</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className={'text-start'}>1</td>
                                    <td className={'text-start'}>HALL</td>
                                    <td className={'text-start'}>3,34</td>
                                </tr>
                                <tr>
                                    <td className={'text-start'}>2</td>
                                    <td className={'text-start'}>STAIRS</td>
                                    <td className={'text-start'}>2,94</td>
                                </tr>
                                <tr>
                                    <td className={'text-start'}>3</td>
                                    <td className={'text-start'}>BATHROOM</td>
                                    <td className={'text-start'}>5,84</td>
                                </tr>
                                <tr>
                                    <td className={'text-start'}>4</td>
                                    <td className={'text-start'}>BEDROOM</td>
                                    <td className={'text-start'}>10,15</td>
                                </tr>
                                <tr>
                                    <td className={'text-start'}>5</td>
                                    <td className={'text-start'}>BEDROOM</td>
                                    <td className={'text-start'}>11,53</td>
                                </tr>
                                <tr>
                                    <td className={'text-start'}>6</td>
                                    <td className={'text-start'}>BEDROOM</td>
                                    <td className={'text-start'}>8,89</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <h4 className={'my-5'}>Attic</h4>
                        <div>
                            <p>Each house is equipped with an uninsulated attic with a floor area of approximately 40m2,
                                which can be used as storage space. The attic hatch - a staircase lowered from the
                                ceiling - is located in the hall.</p>
                            <p>We also offer a version with an insulated attic with OSB panels covering the attic and
                                floor (extra paid option)</p>
                        </div>
                    </div>
                </div>

            </div>

            <a name={'dostepnosc'}/>
            <div className={' container px-4 py-4 my-5'}>
                <div><h1 className={'display-4 fw-bold sectionTitle'}>Availability</h1></div>
                <div className={'fw-bold'}>Planned completion date is by the end of June 2023 and planned handover date before the end of September 2023.</div>
                <StaticImage src={"../images/pzt3d.jpeg"} placeholder="" alt={"pzt"}
                             className={'align-text-bottom my-5'}/>
                <div className={'container px-4 w-100 my-5 py-5 py-4 bg-danger bg-opacity-25'}>
                    <h3>
                        ATTENTION!<br/>
                    </h3>
                    <b>All units in this stage have been sold. Starting from January 2024, we will begin selling two additional units in this location, with the construction completion scheduled for the fourth quarter of 2024. If you are interested, please contact us at biuro@domforte.pl</b>
                </div>
                <div className={'container px-4 w-100 my-5 py-2'}>
                    <div className="auto-resizable-iframe w-75">
                        <div>
                            <iframe src="https://www.youtube.com/embed/wKkZur2qjcU?si=KSjJVcx8bAcuYBoM" title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
                <div className={'container px-4 w-100 my-4 py-4 '} style={{"overflowX": "scroll"}}>
                    <table className={'w-100 text-center niceTable'}>
                        <thead>
                        <tr>
                            <th>SYMBOL</th>
                            <th>AREA</th>
                            <th>GARDEN</th>
                            <th>AVAILABILITY</th>
                            <th>PLAN</th>
                            <th>PRICE</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>A</td>
                            <td>77,27m2</td>
                            <td>153m2</td>
                            <td>SOLD</td>
                            {/*<td className={'df-text-green'}>AVAILABLE</td>*/}
                            <td>
                                <LightGallery plugins={[lgZoom]} mode="lg-fade">
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_a_0.jpg">GROUND FLOOR</a>
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_a_1.jpg">FIRST FLOOR</a>
                                </LightGallery></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>B</td>
                            <td>77,27m2</td>
                            <td>204m2</td>
                            <td>SOLD</td>
                            {/*<td className={'df-text-green'}>AVAILABLE</td>*/}
                            <td>
                                <LightGallery plugins={[lgZoom]} mode="lg-fade">
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_b_0.jpg">GROUND FLOOR</a>
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_b_1.jpg">FIRST FLOOR</a>
                                </LightGallery></td>
                            <td></td>

                        </tr>
                        <tr>
                            <td>C</td>
                            <td>84,65m2</td>
                            <td>198m2</td>
                            {/*<td>RESERVATION</td>*/}
                            {/*<td className={'df-text-green'}>AVAILABLE</td>*/}
                            <td>SOLD</td>
                            <td>
                                <LightGallery plugins={[lgZoom]} mode="lg-fade">
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_c_0.jpg">GROUND FLOOR</a>
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_c_1.jpg">FIRST FLOOR</a>
                                </LightGallery></td>
                            <td>799 000 zł</td>

                        </tr>
                        <tr>
                            <td>D</td>
                            <td>84,65m2</td>
                            <td>86m2</td>
                            <td>SOLD</td>
                            {/*<td className={'df-text-green'}>AVAILABLE</td>*/}
                            <td>
                                <LightGallery plugins={[lgZoom]} mode="lg-fade">
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_d_0.jpg">GROUND FLOOR</a>
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_d_1.jpg">FIRST FLOOR</a>
                                </LightGallery></td>
                            <td></td>

                        </tr>
                        <tr>
                            <td>E</td>
                            <td>84,65m2</td>
                            <td>82m2</td>
                            <td>SOLD</td>
                            {/*<td className={'df-text-green'}>AVAILABLE</td>*/}
                            <td>
                                <LightGallery plugins={[lgZoom]} mode="lg-fade">
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_e_0.jpg">GROUND FLOOR</a>
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_e_1.jpg">FIRST FLOOR</a>
                                </LightGallery></td>
                            <td></td>

                        </tr>
                        <tr>
                            <td>F</td>
                            <td>84,65m2</td>
                            <td>126m2</td>
                            <td>SOLD</td>
                            {/*<td className={'df-text-green'}>AVAILABLE</td>*/}
                            <td>
                                <LightGallery plugins={[lgZoom]} mode="lg-fade">
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_f_0.jpg">GROUND FLOOR</a>
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_f_1.jpg">FIRST FLOOR</a>
                                </LightGallery></td>
                            <td></td>

                        </tr>
                        </tbody>
                    </table>

                </div>
            </div>

            <a name={'dziennik'}/>
            <div className={'container px-4'}>
                <div><h1 className={'display-4 fw-bold sectionTitle'}>Site diary</h1></div>
                <div className={'row my-5 text-center'}>
                    <div className={'col-md-3'}></div>
                    <div className={'col-md-6'}>
                        <LightGallery plugins={[lgZoom]} mode="lg-fade">
                            <a href="/staticimg/dziennik/13.jpg">
                                <img
                                    className="mx-1 my-1"
                                    src="/staticimg/dziennik/13_thumb.jpg"
                                    alt={'2023-04-13'}/>
                            </a>
                            <a href="/staticimg/dziennik/12.jpg">
                                <img
                                    className="mx-1 my-1"
                                    src="/staticimg/dziennik/12_thumb.jpg"
                                    alt={'2022-06-26'}/>
                            </a>
                            <a href="/staticimg/dziennik/11.jpg">
                                <img
                                    className="img-responsive mx-1 my-1"
                                    src="/staticimg/dziennik/11_thumb.jpg"
                                    alt={'2022-06-26'}/>
                            </a>
                            <a href="/staticimg/dziennik/9.jpg">
                                <img className="img-responsive mx-1 my-1"
                                     src="/staticimg/dziennik/9_thumb.jpg"
                                     alt={'2022-05-20'}/>
                            </a>
                            <a href="/staticimg/dziennik/7.jpg">
                                <img className="img-responsive mx-1 my-1"
                                     src="/staticimg/dziennik/7_thumb.jpg"
                                     alt={'2022-04-02'}/>
                            </a>
                            <a href="/staticimg/dziennik/6.jpg">
                                <img className="img-responsive mx-1 my-1"
                                     src="/staticimg/dziennik/6_thumb.jpg"
                                     alt={'2022-03-21'}/>
                            </a>
                            <a href="/staticimg/dziennik/2.jpg">
                                <img className="img-responsive mx-1 my-1"
                                     src="/staticimg/dziennik/2_thumb.jpg"
                                     alt={'2022-03-01'}/>
                            </a>
                        </LightGallery>
                    </div>
                    <div className={'col-md-3'}></div>
                </div>

            </div>
            <a name={'kontakt'}/>
            <div className={'container px-4'}>
                <div><h1 className={'display-4 fw-bold sectionTitle'}>Contact</h1></div>
                <div className={'pt-3'}>
                    Contact us by email at <b>biuro@domforte.pl</b> and by phone at <b>+48 606-388-442</b> from Monday
                    to Friday between 10:00am and 4:00pm.
                </div>
                <div className='py-4 fw-bold'>
                    The project is currently at the open shell stage - we encourage you to make an appointment to view
                    the property.
                </div>
                <div className='py-4 fw-bold'>Planned completion date is by the end of June 2023 and planned handover date before the end of September 2023.</div>
                <div className={'row pt-3'}>
                    <div className={'col-md-4'}>
                        <b>DOMFORTE Sp. z o.o.</b><br/>
                        ul. Zamknięta 10/1.5<br/>
                        30-554 Kraków<br/>
                        tel. +48 606-388-442<br/>
                        email: biuro@domforte.pl<br/>
                    </div>


                </div>

            </div>

            <div className="container">
                <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
                    <p>The leaflet and visualisations are for illustrative purposes only. Adjustments may be made during
                        the implementation phase. The website does not constitute an offer within the meaning of the
                        Civil Code.</p>
                    <p className="col-md-4 mb-0 text-muted">&copy; 2022 Domforte Sp. z o.o.</p>
                </footer>
            </div>
        </ThemeProvider>
    )
        ;
}

export default IndexPage
